
import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Fruits from '../images/fruits.jpg'
import Vegs from '../images/veg.jpg'
import Salads from '../images/lettuce.jpg'
import Cress from '../images/cress.jpg'
import Dairy from '../images/eggs.jpg'
import Mushrooms from '../images/mushrooms.jpg'
import Bread from '../images/bread.jpg'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";

import "./swiper.css";

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const slides = [
    {
        label: 'Fruits',
        img: Fruits,
    },
    {
        label: 'Vegetables',
        img: Vegs,
    },
    {
        label: 'Salads',
        img: Salads,
    },
    {
        label: 'Micro & Cress',
        img: Cress,
    },
    {
        label: 'Dairy',
        img: Dairy,
    },
    {
        label: 'Mushrooms',
        img: Mushrooms,
    },
    {
        label: 'Bread',
        img: Bread,
    }
]


export default function Slider() {
    return (
        <div className='h-80 md:h-92 w-full mx-auto max-w-7xl select-none'>
            <Swiper
                slidesPerView={1.5}
                spaceBetween={10}
                pagination={{ "clickable": true }}
                grabCursor={true}
                breakpoints={{
                    "640": {
                        "slidesPerView": 2.5,
                        "spaceBetween": 50
                    },
                    "768": {
                        "slidesPerView": 3.5,
                        "spaceBetween": 50
                    },
                    "1024": {
                        "slidesPerView": 4.5,
                        "spaceBetween": 50
                    }
                }}
                className="mySwiper"
            >
                {slides.map(({ label, img }) => (
                    <SwiperSlide key={label}>
                        <img
                            className="shadow-md rounded-2xl max-w-60"
                            src={img}
                            alt={label}
                        />
                        {label}
                    </SwiperSlide>
                ))}
                <SwiperSlide>
                    <div className='shadow-md rounded-2xl max-w-60 h-full flex flex-col justify-center items-center'>
                        And more, please see the full catalogue here:
                        <Link
                            to="/products"
                            className="inline-block mt-5 w-9/12 px-5 py-2 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-700 rounded-2xl"
                        >
                            Full Catalogue
                        </Link>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}