import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import DeliveryArea from '../images/delivery-area-map.jpg'
import Future from '../images/future.jpg'
import DeliveryGuy from '../images/delivery_guy.jpg'
import SecureDelivery from '../images/delivery.jpg'
import Slider from "../components/swiper"
import Header from "../components/Header"
// import { StaticImage } from "gatsby-plugin-image"
// import Footer from "../components/footer_v2"

const IndexPage = () => (
	<Layout>
		<Seo title="Home" />

		<Header />


		<section className="py-20" id="products">
			<div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
				<h2 className="w-full text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">Products</h2>
				<p className="mb-5 text-lg text-gray-700 text md:text-left text-center mx-auto">
					We have an extensive range of products
					to make sure that we have what you need.
				</p>
				<Slider />
				<Link
					to="/products"
					className="inline-block w-max mx-auto px-20 py-4 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-600 rounded-2xl"
				>
					Full catalogue
				</Link>
			</div>
		</section>

		<section className="py-20" id="delivery-area">
			<div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
				<h2 className="w-full text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">Delivery Area</h2>
				<p className="mb-5 text-lg text-gray-700 text md:text-left text-center mx-auto max-w-4xl">
					Please see below for our current delivery area but please feel free to contact us should your establishment be outside
					this as we are always willing to work with new customers and hopefully find a solution to their delivery requirements.
				</p>
				<img
					className="rounded-2xl h-96 object-contain"
					src={DeliveryArea}
					loading="lazy"
					alt="Delivery Area Map"
				/>
			</div>
		</section>

		<section className="py-20">
			<div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
				<h2 className="w-full mb-10 text-2xl font-bold text-primary text-center sm:text-3xl md:text-4xl">About us</h2>

				<div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
					<div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
						<img
							className="shadow-xl rounded-2xl"
							src={Future}
							alt=""
						/>
					</div>
					<div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
						<h3 className="mt-2 text-2xl sm:text-left md:text-3xl">Constant attempt to grow</h3>
						<p className="mt-5 text-lg text-gray-700 text md:text-left">
							As a small business that has been growing a lot in the last few months,
							we are always looking for shining stars to join us and be part of our growth.
						</p>
						<span className="relative inline-flex mt-7 w-max">
							<Link
								to="/contact-us"
								className="inline-block px-20 py-4 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-600 rounded-2xl"
							>
								Join us
							</Link>
							<span
								className="absolute top-0 right-0 px-4 py-2 -mt-3 -mr-10 text-xs font-medium leading-tight text-white bg-purple-500 rounded-full"
							>
								We are hiring!
							</span>
						</span>
					</div>
				</div>

				<div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
					<div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
						<img
							className="shadow-xl rounded-2xl max-h-80"
							src={DeliveryGuy}
							alt=""
						/>
					</div>
					<div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
						<h3 className="mt-2 text-2xl sm:text-left md:text-3xl">On-time deliveries</h3>
						<p className="mt-5 text-lg text-gray-700 text md:text-left">
							We make sure your deliveries arrive on time and
							delivered with a smile.
						</p>
					</div>
				</div>

				<div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
					<div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
						<img
							className="shadow-xl rounded-2xl"
							src={SecureDelivery}
							alt=""
						/>
					</div>
					<div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
						<h3 className="mt-2 text-2xl sm:text-left md:text-3xl">COVID-19 measures</h3>
						<p className="mt-5 text-lg text-gray-700 text md:text-left">
							We are constantly updating our procedures in line with the latest COVID-19 security measures.
						</p>
					</div>
				</div>

			</div>
		</section>
	</Layout>
)

export default IndexPage
