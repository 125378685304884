import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Navbar from './navbar'

import Bg from '../../images/fruits_bg.png'
import Bg2 from '../../images/fruits_veg_landing_no_bg_2.png'

const Header = () => {
    return (
        <section className="bg-dark text-white px-8">
            {/* <Navbar /> */}

            {/* <div className="flex flex-col items-center justify-between w-full lg:flex-row px-6 mx-auto max-w-7xl"> */}
            <div className="flex flex-col lg:flex-row mx-auto pt-16 pb-28 max-w-7xl xl:px-12">
                <div className="relative z-10 w-full space-y-10 lg:w-1/2 my-auto flex flex-col items-center lg:items-start">
                    <h1 className="text-center lg:text-left text-5xl font-bold md:text-6xl lg:text-7xl">The right food delivery supplier for your business!</h1>
                    <p className="text-base text-gray-500 sm:text-lg">
                        Field to Fork Produce is a small growing Foodservice supplier,
                        that delivers with quality and passion.
                        We’ve been growing a lot over the last few months,
                        so we are looking for bright people and businesses to join us.
                    </p>
                    <span className="relative inline-flex mt-3">
                        <Link
                            to="/contact-us"
                            className="inline-block px-20 py-4 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-700 rounded-2xl"
                        >
                            Join us
                        </Link>
                        <span
                            className="absolute top-0 right-0 px-4 py-2 -mt-3 -mr-10 text-xs font-medium leading-tight text-white bg-purple-500 rounded-full"
                        >
                            We are hiring!
                        </span>
                    </span>
                </div>
                <img
                    src={Bg2}
                    className='hidden lg:flex w-full max-h-96 lg:w-1/2 rounded-3xl'
                    alt="landing photo"
                />
            </div>
        </section>
    )
}

export default Header
